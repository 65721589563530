import { graphql, Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import React from "react";
import { HTMLContent } from "../components/Content";
import Layout from "../components/layout";
import SEO from "../components/seo";

export const ServicesPageTemplate = ({ content, post }) => {
  return (
    <div className="container mx-auto px-6  text-white flex flex-col  ">
      <div className="max-w-4xl  mb-12 ">
        <p className="mt-6 leading-relaxed sm:text-lg md:text-2xl xl:text-2xl text-white">
          {post.frontmatter.lead_paragraph}
        </p>
        <HTMLContent
          className="text-lg mb-2 font-light pt-6 netlify-html text-white "
          content={content}
        />
      </div>

      {post.frontmatter.service_category.map((categoryObj) => {
        let category = categoryObj.category;
        if (!category) {
          return undefined;
        }

        return (
          <div className="mb-8" key={category.id}>
            <a
              className="text-3xl sm:text-4xl md:text-5xl xl:text-4xl leading-tight  text-white font-bold mb-3 font-display no-underline hover:underline hover:text-gladior-pink "
              href={category.fields.slug}
            >
              {category.id}
            </a>
            <p className="mt-6 leading-relaxed sm:text-lg md:text-xl xl:text-lg text-white">
              {category.lead_paragraph}
            </p>
            <div className="mt-4 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-4">
              {category.services &&
                category.services.map((service) => {
                  return (
                    <div
                      className="flex flex-col items-center justify-center w-full"
                      key={service.id}
                    >
                      <div className="flex flex-col  h-full w-full">
                        <div className="flex flex-col flex-grow">
                          <div className="bg-gladior-dark w-full h-48 flex flex-col justify-center content-center items-center mb-3">
                            {service.background && (
                              <BackgroundImage
                                Tag="section"
                                aria-label="Fullscreen Background"
                                backgroundColor={`#20252c`}
                                className=" w-full h-48 flex flex-col justify-center content-center items-center mb-3"
                                fluid={service.background.childImageSharp.fluid}
                                style={{
                                  backgroundPosition: `center`,
                                }}
                              >
                                <>
                                  {service.thumbnail &&
                                    service.thumbnail.childImageSharp && (
                                      <Img
                                        alt={service.id}
                                        fixed={
                                          service.thumbnail.childImageSharp
                                            .fixed
                                        }
                                        title={service.id}
                                      />
                                    )}
                                  <h3 className="text-lg text-white font-bold font-display">
                                    {service.id}
                                  </h3>
                                </>
                              </BackgroundImage>
                            )}

                            {/* {service.thumbnail && (
                              <Img
                                alt={service.id}
                                fixed={service.thumbnail.childImageSharp.fixed}
                                title={service.id}
                              />
                            )} */}
                            {/* {service.background && (
                              <Img
                                alt={service.id}
                                fixed={service.background.childImageSharp.fixed}
                                title={service.id}
                              />
                            )} */}
                          </div>
                          <p className="text-base mb-2 font-light  ">
                            {service.caption}
                          </p>
                        </div>

                        <div className=" flex-grow-0 text-white flex flex-col justify-start content-start items-start text-center mt-2 mb-8 ">
                          <Link
                            className="bg-transparent hover:bg-gladior-pink  text-white font-semibold hover:text-white py-2 px-4 border border-gladior-pink hover:border-transparent rounded"
                            to={service.fields.slug}
                          >
                            Meer informatie
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const ServicesPage = ({ data }) => {
  const { markdownRemark: post } = data;
  return (
    <>
      <SEO
        description={post.frontmatter.seo.meta_description}
        pathname={post.frontmatter.path}
        title={post.frontmatter.seo.meta_title}
      />
      <Layout
        heroBackgroundImage={post.frontmatter.hero_background_image}
        title={post.frontmatter.title}
      >
        <main className="bg-gladior-dark ">
          <section className="container mx-auto px-6 py-10  max-w-7xl ">
            <ServicesPageTemplate
              content={post.html}
              contentComponent={HTMLContent}
              post={post}
            />
          </section>
        </main>
      </Layout>
    </>
  );
};

ServicesPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ServicesPage;

export const servicesPageQuery = graphql`
  query ServicesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        hero_background_image {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        seo {
          meta_description
          meta_title
        }
        title
        path
        lead_paragraph

        service_category {
          category {
            id
            lead_paragraph
            services {
              id
              thumbnail {
                childImageSharp {
                  fixed(height: 100) {
                    ...GatsbyImageSharpFixed_withWebp_noBase64
                  }
                }
              }
              background {
                childImageSharp {
                  fixed(height: 100) {
                    ...GatsbyImageSharpFixed_withWebp_noBase64
                  }
                  fluid(quality: 90, maxWidth: 4160) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              title
              caption
              fields {
                slug
              }
            }
            fields {
              slug
            }
          }
        }
      }
    }
  }
`;
